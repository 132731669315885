import { useParams } from "react-router-dom";
import "../styles/home.css";
import Counter from "../components/counter";
import React, { useState, useEffect } from 'react';

function Dashboard() {
    const [details,setDetails] = useState(useParams())
    const [count, setCount] = useState(0)
    useEffect(() => {
        console.log(details)
      },[details]);

  return (
    <div>
        <h1>hello {details.name} {details.surname} count: {count}</h1>
        <Counter updateCount={setCount} count={count} />
    </div>
  );
}

export default Dashboard; 