import logo from "../images/Logo-blue/Logo Files/For Web/svg/Color logo - no background.svg";
import "../styles/home.css";
import arrow from "../images/arrow.svg";
import coins from "../images/coins.svg";
import magnifying from "../images/magnifying.svg";
import pie from "../images/pie.svg";
import settings from "../images/settings.svg";
import { useEffect } from "react";

function Home() {
  function triggerAnimation(entries) {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        entry.target.classList.add("animate__fadeIn");
      } else {
        entry.target.classList.remove("animate__fadeIn");
      }
    });
  }
  useEffect(() => {
    const elements = Array.from(document.getElementsByClassName("feature"));
    elements.forEach((element) => {
      const headerObserver = new IntersectionObserver(triggerAnimation, {
        root: null,
        threshold: 0,
        rootMargin: `0px 0px 0px 0px`,
      });
      headerObserver.observe(element);
    });
  });

  return (
    <div>
      <nav className="navbar navbar-expand-md " role="navigtion" id="myNav">
        <div className="container-fluid d-flex justify-content-center px-0">
          <div className="row w-100">
            <div className="col-6 col-md-4 d-flex justify-content-start align-items-center">
              <a className="navbar-brand d-flex align-items-center" href="#">
                <img
                  src={logo}
                  alt="Logo"
                  className="d-inline-block align-text-top logo"
                />
                <h1 className="brandName">Budgie</h1>
              </a>
            </div>
            <div className="col-6  col-md-1 d-flex justify-content-end align-items-center">
              <button
                className="navbar-toggler"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbarNavAltMarkup"
                aria-controls="navbarNavAltMarkup"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="bi bi-grid"
                  viewBox="0 0 16 16"
                >
                  <path d="M1 2.5A1.5 1.5 0 0 1 2.5 1h3A1.5 1.5 0 0 1 7 2.5v3A1.5 1.5 0 0 1 5.5 7h-3A1.5 1.5 0 0 1 1 5.5v-3zM2.5 2a.5.5 0 0 0-.5.5v3a.5.5 0 0 0 .5.5h3a.5.5 0 0 0 .5-.5v-3a.5.5 0 0 0-.5-.5h-3zm6.5.5A1.5 1.5 0 0 1 10.5 1h3A1.5 1.5 0 0 1 15 2.5v3A1.5 1.5 0 0 1 13.5 7h-3A1.5 1.5 0 0 1 9 5.5v-3zm1.5-.5a.5.5 0 0 0-.5.5v3a.5.5 0 0 0 .5.5h3a.5.5 0 0 0 .5-.5v-3a.5.5 0 0 0-.5-.5h-3zM1 10.5A1.5 1.5 0 0 1 2.5 9h3A1.5 1.5 0 0 1 7 10.5v3A1.5 1.5 0 0 1 5.5 15h-3A1.5 1.5 0 0 1 1 13.5v-3zm1.5-.5a.5.5 0 0 0-.5.5v3a.5.5 0 0 0 .5.5h3a.5.5 0 0 0 .5-.5v-3a.5.5 0 0 0-.5-.5h-3zm6.5.5A1.5 1.5 0 0 1 10.5 9h3a1.5 1.5 0 0 1 1.5 1.5v3a1.5 1.5 0 0 1-1.5 1.5h-3A1.5 1.5 0 0 1 9 13.5v-3zm1.5-.5a.5.5 0 0 0-.5.5v3a.5.5 0 0 0 .5.5h3a.5.5 0 0 0 .5-.5v-3a.5.5 0 0 0-.5-.5h-3z" />
                </svg>
              </button>
            </div>

            <div className="col-12 col-md-7  d-flex justify-content-md-center align-items-center">
              <div
                className="collapse navbar-collapse justify-content-end"
                id="navbarNavAltMarkup"
              >
                <div
                  className="navbar-nav "
                  onClick={(e) => {
                    document
                      .getElementById("navbarNavAltMarkup")
                      .classList.toggle("show");
                  }}
                >
                  <div className="d-flex flex-column flex-md-row justify-content-end align-items-center links">
                    <a className="nav-link " href="#featureSection">
                      Features
                    </a>
                    <a className="nav-link " href="#about">
                      About
                    </a>
                    <a className="nav-link " href="#security">
                      Security
                    </a>
                    <a className="nav-link sub" href="http://eepurl.com/in7JPA">
                      Subscribe
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </nav>

      <div className="section landing container-fluid px-4 d-flex">
        <div className="container launchMainContent d-flex flex-column justify-content-center">
          <h1 className=" animate__animated animate__fadeIn animate__delay-4s comingSoon">
            Coming soon
          </h1>
          <h1 className="animate__animated animate__fadeInDown animate__delay-1s">
            MONEY.
          </h1>
          <h1 className="animate__animated animate__fadeInDown animate__delay-2s mt-4">
            SIMPLIFIED
          </h1>
          <h2 className="intro animate__animated animate__fadeIn animate__delay-3s">
            Untangle the mess and unlock the power of your money in minutes
          </h2>

          <div className="seeHowItWorks d-flex flex-column align-items-center justify-content-center pt-5 animate__animated animate__fadeIn animate__delay-4s">
            <h3>SEE HOW IT WORKS </h3>
            <a href="#divider">
              <img
                className="arrow animate__animated animate__bounce animate__delay-5s"
                src={arrow}
                alt=""
              />
            </a>
          </div>
        </div>
      </div>
      <div className="section features container-fluid px-5" id="featureSection">
        <div id="divider"></div>
        <p id="featuresHead">
          Answer some questions, connect your accounts securely and then let our
          smart algorithms get to work...
        </p>

        <div className="featureContainer d-flex justify-content-start">
          <div className="feature col-md-4 col-12 d-flex flex-column align-items-start animate__animated animate__slow">
            <h3>
              <img src={coins} alt="money" /> Save On Bills
            </h3>
            <p>
              Make sure your not overpaying on bills. We’ll check for better
              deals for you
            </p>
          </div>
        </div>
        <div className="featureContainer d-flex justify-content-end">
          <div className="feature col-md-4 col-12 d-flex flex-column align-items-start animate__animated animate__slow">
            <h3>
              <img src={pie} alt="pie-chart" /> Smart Budgeting
            </h3>
            <p>
              Not sure about the numbers? We calculate a realistic budget for
              you based on your current spending and your goals.
            </p>
          </div>
        </div>
        <div className="featureContainer d-flex justify-content-start">
          <div className="feature col-md-4 col-12 d-flex flex-column align-items-start animate__animated animate__slow">
            <h3>
              <img src={magnifying} alt="magnifying glass" /> Insights/Analytics
            </h3>
            <p>
              We'll analyse your spending and share with you the important
              insights so you can make smart, informed decisions
            </p>
          </div>
        </div>
        <div className="featureContainer d-flex justify-content-end">
          <div className="feature col-md-4 col-12 d-flex flex-column align-items-start animate__animated animate__slow">
            <h3>
              <img src={settings} alt="settings" /> Tailored Plans
            </h3>
            <p>
              Export your results as a personalised spreadsheet to track your
              money and reach your goals
            </p>
          </div>
        </div>
      </div>

      <div className="section more container-fluid px-4">
        <div className="row">
          <div className="col-12 col-md-6 d-flex justify-content-center  align-items-center">
            <h1 className="title">Smart, Simple, Effective.</h1>
          </div>
          <div className="col-12 col-md-6 d-flex justify-content-center align-items-center">
            <p>
              Budgie is built to take care of the process so you don't have to.{" "}
              <br />
              <br /> With smart and powerfull features, we don't stop there, we
              handle the complicated stuff so you can focus on the important
              decisions. <br />
              <br />
              We believe our new approach to money management will allow
              everyone to make maximum use of their money with minimum effort.
            </p>
          </div>
        </div>
      </div>
      <div className="section security container-fluid px-4" id="security">
        <div className="row flex-column-reverse flex-md-row">
          <div className="col-12 col-md-6 d-flex flex-column justify-content-center align-items-center ">
            <div className="d-flex flex-column pb-4 list">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                className="bi bi-building-fill-lock"
                viewBox="0 0 16 16"
              >
                <path d="M2 1a1 1 0 0 1 1-1h10a1 1 0 0 1 1 1v7.764a3 3 0 0 0-4.989 2.497 2.01 2.01 0 0 0-.743.739H6.5a.5.5 0 0 0-.5.5V16H3a1 1 0 0 1-1-1V1Zm2 1.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5Zm3 0v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5Zm3.5-.5a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1ZM4 5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5ZM7.5 5a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1Zm2.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5ZM4.5 8a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1Zm2.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5Z" />
                <path d="M9 13a1 1 0 0 1 1-1v-1a2 2 0 1 1 4 0v1a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1h-4a1 1 0 0 1-1-1v-2Zm3-3a1 1 0 0 0-1 1v1h2v-1a1 1 0 0 0-1-1Z" />
              </svg>
              <p>Bank level security</p>
            </div>
            <div className="d-flex flex-column pb-4 list">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                className="bi bi-file-earmark-lock"
                viewBox="0 0 16 16"
              >
                <path d="M10 7v1.076c.54.166 1 .597 1 1.224v2.4c0 .816-.781 1.3-1.5 1.3h-3c-.719 0-1.5-.484-1.5-1.3V9.3c0-.627.46-1.058 1-1.224V7a2 2 0 1 1 4 0zM7 7v1h2V7a1 1 0 0 0-2 0zM6 9.3v2.4c0 .042.02.107.105.175A.637.637 0 0 0 6.5 12h3a.64.64 0 0 0 .395-.125c.085-.068.105-.133.105-.175V9.3c0-.042-.02-.107-.105-.175A.637.637 0 0 0 9.5 9h-3a.637.637 0 0 0-.395.125C6.02 9.193 6 9.258 6 9.3z" />
                <path d="M14 14V4.5L9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2zM9.5 3A1.5 1.5 0 0 0 11 4.5h2V14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h5.5v2z" />
              </svg>
              <p>We can only read your data</p>
            </div>
            <div className="d-flex flex-column pb-4 list">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                className="bi bi-person-fill-lock"
                viewBox="0 0 16 16"
              >
                <path d="M11 5a3 3 0 1 1-6 0 3 3 0 0 1 6 0Zm-9 8c0 1 1 1 1 1h5v-1a1.9 1.9 0 0 1 .01-.2 4.49 4.49 0 0 1 1.534-3.693C9.077 9.038 8.564 9 8 9c-5 0-6 3-6 4Zm7 0a1 1 0 0 1 1-1v-1a2 2 0 1 1 4 0v1a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1h-4a1 1 0 0 1-1-1v-2Zm3-3a1 1 0 0 0-1 1v1h2v-1a1 1 0 0 0-1-1Z" />
              </svg>
              <p>Your data is never shared without permission</p>
            </div>
          </div>
          <div className="col-12 col-md-6 pb-4 d-flex justify-content-center  align-items-center">
            <h1 className="title">
              Protection, as priority
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                className="bi bi-lock-fill"
                viewBox="0 0 16 16"
              >
                <path d="M8 1a2 2 0 0 1 2 2v4H6V3a2 2 0 0 1 2-2zm3 6V3a3 3 0 0 0-6 0v4a2 2 0 0 0-2 2v5a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V9a2 2 0 0 0-2-2z" />
              </svg>
            </h1>
          </div>
        </div>
      </div>
      <div className="section more about container-fluid px-4" id="about">
        <div className="row">
          <div className="col-12 col-md-6 d-flex justify-content-center  align-items-center">
            <h1 className="title">About Us</h1>
          </div>
          <div className="col-12 col-md-6 d-flex justify-content-md-start align-items-center">
            <p>
              Our mission here at Budgie is simple. We want to make money
              management more accessible. We recognise that powerful tools are
              not enough to get the average working person managing their money
              well. Money management tools require a learning curve which
              ultimately puts people off. That is where we come into it. Most of
              us do not have the time to learn all these tools and then apply
              them in a structured, effective and consistent way. However, in a
              time where money management is so crucial, something must be done
              to equip people with good money management habits regardless of
              their ability.
            </p>
          </div>
        </div>
      </div>
      <div
        className="section signUp container-fluid px-4 justify-content-center align-items-center"
        id="signUp"
      >
        <h1 className="title">Coming soon...</h1>
        <p>
          We appreciate and value those who believe in our mission and what we
          are trying to build. If early access, benefits, contributing or just
          following our progress sounds good, please enter your email to stay in
          touch... we won’t spam you :)
        </p>
        <div className="d-flex justify-content-center">
          <a
            className="btn btn-primary mt-3 quickLinks"
            href="http://eepurl.com/in7JPA"
          >
            SUBSCRIBE
          </a>
        </div>
      </div>
    </div>
  );
}

export default Home;
