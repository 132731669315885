import "../styles/home.css";
import React, { useState, useEffect } from 'react';

function Counter(props) {
    
    function add(count){
        return count+2
    }
    function minus(count){
        return count-2
    }
    return (
        <div>
            <button onClick={()=>{props.updateCount(add(props.count))}}>add</button>
            <button onClick={()=>{props.updateCount(minus(props.count))}}>minus</button>
        </div>
      );
    
}
export default Counter